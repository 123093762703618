import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { io } from 'socket.io-client'
import {
  setCrashGamePlacedBets, setCrashGameRoundGraphTimerData, setCrashGameRoundRoundBettingOnHoldData, setCrashGameRoundStartedData,
  setCrashGameRoundStoppedData, setCrashGameRoundWaitingTimerData, setFreeBetRainData, setNewMessageByWS, setPlayerUnseenMessage, setSocketInstance, setStopFreeBetRainDropData
} from '../redux-store/redux/gameSocketData'
import { setUserBalance } from '../redux-store/redux/user'

const SOCKET_NAMESPACES = {
  DEMO: '/demo',
  WALLET: '/wallet',
  CRASH_GAME: '/crash-game',
  CHAT: '/chat',
  FREE_BET: '/free-bet'
}

export const SOCKET_LISTENERS = {
  DEMO_HELLO_WORLD: SOCKET_NAMESPACES.DEMO + '/helloWorld',
  WALLET_USER_WALLET_BALANCE: SOCKET_NAMESPACES.WALLET + '/userWalletBalance',
  CRASH_GAME_ROUND_STARTED: SOCKET_NAMESPACES.CRASH_GAME + '/roundStarted',
  CRASH_GAME_ROUND_STOPPED: SOCKET_NAMESPACES.CRASH_GAME + '/roundStopped',
  CRASH_GAME_WAITING_TIMER: SOCKET_NAMESPACES.CRASH_GAME + '/waitingTimer',
  CRASH_GAME_GRAPH_TIMER: SOCKET_NAMESPACES.CRASH_GAME + '/graphTimer',
  CRASH_GAME_ROUND_BETTING_ON_HOLD: SOCKET_NAMESPACES.CRASH_GAME + '/roundBettingOnHold',
  CRASH_GAME_PLACED_BETS: SOCKET_NAMESPACES.CRASH_GAME + '/placedBets',
  CHAT_PLAYER_UNSEEN_MENTION_COUNT: SOCKET_NAMESPACES.CHAT + '/playerUnSeenMentionCount',
  CHAT_NEW_MESSAGE: SOCKET_NAMESPACES.CHAT + '/newMessage',
  FREE_BET_RAIN_DROP: SOCKET_NAMESPACES.FREE_BET + '/freeBetRainDrop',
  STOP_FREE_BET_RAIN_DROP: SOCKET_NAMESPACES.FREE_BET + '/stopFreeBetRainDrop'
}

export const SOCKET_EMITTERS = {
  DEMO_HELLO_WORLD: SOCKET_NAMESPACES.DEMO + '/helloWorld',
  CHAT_SEND_NEW_MESSAGE: SOCKET_NAMESPACES.CHAT + '/sendNewMessage',
  CHAT_CHANGE_CHAT_ROOM: SOCKET_NAMESPACES.CHAT + '/changeChatRoom'
}

// const token = sessionStorage.getItem('token') || ''
const WS_URL = process.env.REACT_APP_REST_WS_URL
const WebSocketConnectionProvider = ({ children }) => {
  const { token: accessToken } = useSelector(state => state.user)

  const dispatch = useDispatch()

  useEffect(() => {
    if (accessToken) {
      const newMessageIO = io(`${WS_URL}${SOCKET_NAMESPACES.CHAT}`, {
        transports: ['websocket'],
        auth: {
          authorization: `Bearer ${accessToken}`
        }
      })

      const freeBetIO = io(`${WS_URL}${SOCKET_NAMESPACES.FREE_BET}`, {
        transports: ['websocket'],
        auth: {
          authorization: `Bearer ${accessToken}`
        }
      })

      const walletIO = io(`${WS_URL}/wallet`, {
        transports: ['websocket'],
        auth: {
          authorization: `Bearer ${accessToken}`
        }
      })

      walletIO.on(SOCKET_LISTENERS.WALLET_USER_WALLET_BALANCE, (data) => {
        dispatch(setUserBalance(data?.data))
      })

      dispatch(setSocketInstance(newMessageIO))

      newMessageIO.on(SOCKET_LISTENERS.CHAT_NEW_MESSAGE, (data) => {
        dispatch(setNewMessageByWS(data?.data))
      })

      newMessageIO.on(SOCKET_LISTENERS.CHAT_PLAYER_UNSEEN_MENTION_COUNT, (data) => {
        dispatch(setPlayerUnseenMessage(data?.data))
      })

      freeBetIO.on(SOCKET_LISTENERS.FREE_BET_RAIN_DROP, (data) => {
        dispatch(setFreeBetRainData(data?.data))
      })

      freeBetIO.on(SOCKET_LISTENERS.STOP_FREE_BET_RAIN_DROP, (data) => {
        dispatch(setStopFreeBetRainDropData(data?.data))
      })
    }
  }, [accessToken])

  useEffect(() => {
    const crashGameIO = io(`${WS_URL}/crash-game`, {
      transports: ['websocket']
    })

    crashGameIO.on(SOCKET_LISTENERS.CRASH_GAME_ROUND_STARTED, (data) => {
      dispatch(setCrashGameRoundStartedData(data?.data))
    })
    crashGameIO.on(SOCKET_LISTENERS.CRASH_GAME_ROUND_STOPPED, (data) => {
      dispatch(setCrashGameRoundStoppedData(data?.data))
    })
    crashGameIO.on(SOCKET_LISTENERS.CRASH_GAME_WAITING_TIMER, (data) => {
      dispatch(setCrashGameRoundWaitingTimerData(data?.data))
    })
    crashGameIO.on(SOCKET_LISTENERS.CRASH_GAME_GRAPH_TIMER, (data) => {
      dispatch(setCrashGameRoundGraphTimerData(data?.data))
    })
    crashGameIO.on(SOCKET_LISTENERS.CRASH_GAME_ROUND_BETTING_ON_HOLD, (data) => {
      dispatch(setCrashGameRoundRoundBettingOnHoldData(data?.data))
    })
    crashGameIO.on(SOCKET_LISTENERS.CRASH_GAME_PLACED_BETS, (data) => {
      dispatch(setCrashGamePlacedBets(data?.data))
    })
  }, [])

  return children
}

export default WebSocketConnectionProvider
