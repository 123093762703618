import { moment } from './../config/moment'

function fallbackCopyTextToClipboard (text) {
  const textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  let successful
  try {
    successful = document.execCommand('copy')
  } catch (err) {
    successful = false
  }

  document.body.removeChild(textArea)
  return successful
}

export const copyToClipboard = (text) => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text)
  }
  return navigator.clipboard.writeText(text).then(function () {
    return true
  }, function (err) {
    console.error('Async: Could not copy text: ', err)
    return false
  })
}

export const momentConvertInTime = (time) => {
  if (time) {
    return moment(time).format('DD MMM YYYY HH:mm:ss')
  } else {
    return ''
  }
}

export const toFixed = (x) => {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1])
    if (e) {
      x *= Math.pow(10, e - 1)
      x = '0.' + (new Array(e)).join('0') + x.toString().substring(2)
    }
  } else {
    let e = parseInt(x.toString().split('+')[1])
    if (e > 20) {
      e -= 20
      x /= Math.pow(10, e)
      x += (new Array(e + 1)).join('0')
    }
  }
  return x
}

export const closeAllModals = () => {
  // get modals
  const modals = document.getElementsByClassName('modal')

  // on every modal change state like in hidden modal
  for (let i = 0; i < modals.length; i++) {
    modals[i].classList.remove('show')
    modals[i].setAttribute('aria-hidden', 'true')
    modals[i].setAttribute('style', 'display: none')
  }

  // get modal backdrops
  const modalsBackdrops = document.getElementsByClassName('modal-backdrop')

  // remove every modal backdrop
  for (let i = 0; i < modalsBackdrops.length; i++) {
    document.body.removeChild(modalsBackdrops[i])
  }

  // remove body class modal
  document.body.classList.remove('modal-open')
}

export const closeModal = (id) => {
  // get modals
  const modals = document.getElementById(id)

  modals.classList.remove('show')
  modals.setAttribute('aria-hidden', 'true')
  modals.setAttribute('style', 'display: none')

  // get modal backdrops
  const modalsBackdrops = document.getElementsByClassName('modal-backdrop')

  // remove every modal backdrop
  for (let i = 0; i < modalsBackdrops.length; i++) {
    document.body.removeChild(modalsBackdrops[i])
  }

  // remove body class modal
  document.body.classList.remove('modal-open')
}

export const stringToColour = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xFF
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

export const truncateString = (str, num, stdOneLineLength) => {
  if (str.split(' ')[0].length > num / 2) {
    str = str.slice(0, num / 2) + '\n' + str.slice(num / 2)
  }

  if (str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

export const getInitials = (str) => {
  if (/^[a-z]+$/i.test(str)) {
    const initials = str.toUpperCase().replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g)
    return initials.slice(0, 2).join('')
  }
  return ''
}

export const replaceMentionWithValue = (str) => {
  let newStr = str
  const nameAvailable = newStr.match(/((.)\[([^[]*)]\(([^(^)]*)\))/g)
  if (nameAvailable?.length) {
    for (let i = 0; i < nameAvailable?.length; i++) {
      const newName = newStr.match(/((.)\[([^[]*)]\(([^(^)]*)\))/i)[3]
      newStr = newStr.replace(/((.)\[([^[]*)]\(([^(^)]*)\))/i, `@${newName} `)
    }
  }
  return newStr
}

export const floatSub = (b, c) => {
  const tens = [10, 100, 1000, 10000, 100000, 1000000]

  const b1 = b.toString().split('.')
  let b1Max = 0
  if (b1.length === 2) {
    b1Max = b1[1].length
  }

  const c1 = c.toString().split('.')
  let c1Max = 0
  if (c1.length === 2) {
    c1Max = c1[1].length
  }

  let maxLen = 0
  let maxVal = 0
  if (b1Max > c1Max) {
    maxVal = tens[b1Max - 1]
    maxLen = b1Max
  } else {
    maxLen = c1Max
    maxVal = tens[c1Max - 1]
  }

  let fv = 0
  if (maxLen === 0) {
    maxVal = 1
    fv = b - c
  } else {
    fv = parseFloat((((b * maxVal) - (c * maxVal)) / maxVal).toFixed(maxLen))
  }
  return fv
}

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}

export const getFileObjectFromAudioObjectUrl = async (audioUrl) => {
  try {
    // Fetch the audio file as a Blob
    const response = await fetch(audioUrl)
    const blob = await response.blob()

    // Create a File object from the Blob
    const fileObject = new File([blob], 'audio.mp3', { type: blob.type })
    return URL.createObjectURL(fileObject)
  } catch (error) {
    console.error('Error fetching audio file:', error)
    return null
  }
}

export const getCalculatedWinningAmount = (multiplier, betAmount, maxBetProfit) => {
  let winningAmount = multiplier * betAmount
  let profit = winningAmount - betAmount

  if (profit > maxBetProfit.amount) {
    profit = maxBetProfit.amount
    winningAmount = betAmount + +maxBetProfit.amount
  }
  return `${winningAmount.toFixed(2)}`
}
