import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  systemGameData: null,
  isBetPlaced: false
}

const {
  actions: {
    setSystemData,
    setIsBetPlaced
  },
  reducer
} = createSlice({
  name: 'gameSetting',
  initialState,
  reducers: {
    setSystemData: (state, action) => ({
      ...state,
      systemGameData: action.payload
    }),
    setIsBetPlaced: (state, action) => ({
      ...state,
      isBetPlaced: action.payload
    })
  }
})

export default reducer
export {
  setSystemData,
  setIsBetPlaced
}
