import moment from 'moment'
import { parseJwt } from '../../utils/helper'
import { axiosClient } from './apiCalls'
import store from '../../redux-store/store'
import { setIsTokenExpired } from '../../redux-store/redux/user'

const tokenKey = 'token'

const getToken = () => {
  return sessionStorage.getItem(tokenKey)
}

const isTokenExpired = () => {
  const decoded = parseJwt(getToken())
  const { user: { token } } = store.getState()

  return (token && decoded) ? decoded?.exp < moment().unix() : false
}

const intercepts = () => {
  axiosClient.interceptors.request.use(async (config) => {
    const requestConfig = config
    if (isTokenExpired()) {
      store.dispatch(setIsTokenExpired(isTokenExpired()))
    }
    const token = sessionStorage.getItem('token') || ''
    if (token.length) requestConfig.headers['access-token'] = token
    return config
  })
  // For GET requests
  axiosClient.interceptors.request.use(
    (req) => {
      // Add configurations here
      return req
    },
    (err) => {
      return Promise.reject(err)
    }
  )

  // For POST requests
  axiosClient.interceptors.response.use(
    (res) => {
      // Add configurations here
      return res
    },
    (err) => {
      return Promise.reject(err)
    }
  )
}

export default intercepts
